<template>
  <v-container class="my-10"
  >
    <h4 class="text-h4 font-italic my-4 font-weight-bold">Productos Destacados</h4>
    <v-row v-if="featuredProducts === null">
      <v-col
        v-for="i in 6"
        :key="i">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          elevation="2"
          max-width="250"
        ></v-skeleton-loader>
      </v-col>
      
    </v-row>
      <v-alert
        v-else-if="featuredProducts.length === 0"
        border="right"
        colored-border type="error"
        elevation="2"
      >
        No hay productos que mostrar.
      </v-alert>
    <v-row v-else>
      <product-item
        v-for="producto in featuredProducts"
        :key="producto.id"
        :product="producto"
        @addToCart="addProductToCart"
        @addToWishlist="addToWishlist(producto)"
        @removeOfWishlist="removeOfWishlist(producto)"
      >
      </product-item>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  export default {
    data () {
      return {
      }
    },
    components: {
      productItem: () => import('@/pages/Products/ProductItem.vue')
    },
    computed: {
      /*
        * destacadosFiltro() sirve para mostrar los productos que tienen la propiedad "destacado"
        * en true, se puede modificar cuantos productos se quieren mostrar. 
      */
      ...mapState('products', ['featuredProducts', 'products']),
      destacadosFiltro() {
        let maxFilteredProds = 0
        return this.products.filter((product, index) => {
          if(product.producto.destacado === false && maxFilteredProds < 6) {
            maxFilteredProds += 1
            return true
          } else {
            return false
          }
        })
      }
    },
    methods: {
      ...mapMutations('cart', ['addProduct']),
      ...mapActions('usuario', ['getWishlistWithPrice', 'addProductToWishlist', 'removeProductOfWishlist']),
      ...mapActions('products', ['setInWishlistFlag', 'setInWishlistFlagFeaturedProducts']),
      addToWishlist(product) {
        // console.log('Product to add', product);
        this.addProductToWishlist(product.producto.id)
        this.updateInWishlistOfProduct(true, product)
      },
      removeOfWishlist(product) {
        this.removeProductOfWishlist(product.producto.id)
        this.updateInWishlistOfProduct(false, product)
      },
      updateInWishlistOfProduct(newValue, product) {
        //console.log('dentro de updateInWishlistOfProduct', newValue, product);
        const index = this.featuredProducts.findIndex(p => p === product)
        // console.log('index', index);
        this.featuredProducts[index].inWishlist = newValue
      },
      addProductToCart(product) {
        this.addProduct(product)
      },
    }
  }
</script>

<style>
.text-h4 {
  font-family: "Montserrat", sans-serif;
  color: #2D4761;
}
</style>
